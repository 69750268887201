import zh_tw from "./zh_tw.json";
import en_us from "./en_us.json";

export type Locales = Record<string, any>;

const languages: any = {
  en_us,
  zh_tw,
};
export default languages;
