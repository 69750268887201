import { put, takeLatest, call, delay } from "redux-saga/effects";
import { userActions } from "@/reducers/user/user.slice";
import {
  AddNewIdentifyAccountRequest,
  ChangePasswordRequest,
  CheckOAuthRequest,
  CheckOAuthResponse,
  GetOTPCodeRequest,
  LoginPayload,
  PostUserAgreementsRequest,
  ResetPasswordRequest,
  SignupRequest,
  ThirdPartyLoginPageMetadataRequest,
  ThirdPartyLoginPageMetadataResponse,
  UpdateAccountRequest,
  UpdateUserProfileRequest,
  UserAgreementsResponse,
  UserAPI,
  ValidateThirdPartyAccountRequest,
  type PostUserEmailRequest,
  UserAgreementRequest,
} from "@/services/userApi";
import { PayloadAction } from "@reduxjs/toolkit";
import { Response } from "@/utils";
import { globalActions } from "@/reducers/global.slice";

export enum ResponseError {
  emailIsAlreadyExisted = "emailIsAlreadyExisted",
  accountIsAlreadyExisted = "accountIsAlreadyExisted",
  otpCodeVerifyFailed = "otpCodeVerifyFailed",
  validationRegex = "validation.regex",
  useSamePassword = "useSamePassword",
  accountIsNotExisted = "accountIsNotExisted",
  loginTemporaryThrottled = "loginTemporaryThrottled",
  validationRequired = "validation.required",
  passwordIsNotValid = "passwordIsNotValid",
  accountIsNotValidType = "accountIsNotValidType",
  RecaptchaTokenVerifyFailed = "RecaptchaTokenVerifyFailed",
  IsNotValidPasswordHash = "IsNotValidPasswordHash",
  overExecutionConcurrentLimit = "overExecutionConcurrentLimit",
  accountTypeNotAllowToUpdate = "accountTypeNotAllowToUpdate",
  unsupportedGrantType = "unsupportedGrantType",
  clientIdOrRedirectUrlIsNotValid = "clientIdOrRedirectUrlIsNotValid",
  invalidInputs = "invalidInputs",
  primaryAccountIsNotValid = "primaryAccountIsNotValid",
  emailIsNotVerified = "emailIsNotVerified",
  validateFailed = "validateFailed",
  canNotGetEmailFromToken = "canNotGetEmailFromToken",
  createFailed = "createFailed",
  tooManyAttempts = "tooManyAttempts",
  accountIsExisted = "accountIsExisted",
  accountIsNotExistedOrBelongToYou = "accountIsNotExistedOrBelongToYou",
  accountTypeIsNotMatch = "accountTypeIsNotMatch",
  accountAlreadyIsPrimary = "accountAlreadyIsPrimary",
  accountIsPrimary = "accountIsPrimary",
  clientIsNotExists = "clientIsNotExists",
  hasNoDefaultAlgo = "hasNoDefaultAlgo",
}

// 統一管理 error messages snack bar content
function* ErrorMessageSnackBar(error: any) {
  const errorMessage = error.data?.errors[0].message;
  switch (errorMessage) {
    // 帳號重複
    case ResponseError.accountIsAlreadyExisted:
    case ResponseError.emailIsAlreadyExisted:
    case ResponseError.accountIsExisted:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountExisted",
        })
      );
      return;
    // 驗證碼錯誤
    case ResponseError.otpCodeVerifyFailed:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.otpCodeVerifyFailed",
        })
      );
      return;
    // 登入時錯誤太多次，需要跳出 Recaptcha
    case ResponseError.loginTemporaryThrottled:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.login.fail.throttled",
        })
      );
      yield put(userActions.showReCaptcha());
      return;
    // 更新密碼時，新舊密碼重複
    case ResponseError.useSamePassword:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.useSamePassword",
        })
      );
      return;
    // 帳號不存在
    case ResponseError.accountIsNotExisted:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountIsNotExisted",
        })
      );
      return;
    // 登入時，client_id 或 redirect_url 錯誤
    case ResponseError.validationRequired:
    case ResponseError.clientIdOrRedirectUrlIsNotValid:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content:
            "notice.snackbar.errorMessage.clientIdOrRedirectUrlIsNotValid",
        })
      );
      return;
    // 密碼錯誤
    case ResponseError.passwordIsNotValid:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.passwordIsNotValid",
        })
      );
      return;
    // 帳號格式錯誤
    case ResponseError.accountIsNotValidType:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountIsNotValidType",
        })
      );
      return;
    // Recapcha token 錯誤
    case ResponseError.RecaptchaTokenVerifyFailed:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.RecaptchaTokenVerifyFailed",
        })
      );
      return;
    // 密碼加密失敗
    case ResponseError.IsNotValidPasswordHash:
    case ResponseError.hasNoDefaultAlgo:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.IsNotValidPasswordHash",
        })
      );
      return;
    // 有相同 api 正在執行中
    case ResponseError.overExecutionConcurrentLimit:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.overExecutionConcurrentLimit",
        })
      );
      return;
    // 帳號類型不允許更新
    case ResponseError.accountTypeNotAllowToUpdate:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountTypeNotAllowToUpdate",
        })
      );
      return;
    // 不支援的授權類型
    case ResponseError.unsupportedGrantType:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.unsupportedGrantType",
        })
      );
      return;
    // 輸入資料錯誤
    case ResponseError.invalidInputs:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.invalidInputs",
        })
      );
      return;
    // 主要帳號驗證錯誤
    case ResponseError.primaryAccountIsNotValid:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.primaryAccountIsNotValid",
        })
      );
      return;
    // Email 未驗證
    case ResponseError.emailIsNotVerified:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.emailIsNotVerified",
        })
      );
      return;
    // 驗證失敗
    case ResponseError.validateFailed:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.validateFailed",
        })
      );
      return;
    // 未能從 token 取得 email
    case ResponseError.canNotGetEmailFromToken:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.canNotGetEmailFromToken",
        })
      );
      return;
    // 新增、建立失敗
    case ResponseError.createFailed:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.createFailed",
        })
      );
      return;
    // 嘗試次數過多
    case ResponseError.tooManyAttempts:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.tooManyAttempts",
        })
      );
      return;
    //  帳號不存在或不屬於此帳戶
    case ResponseError.accountIsNotExistedOrBelongToYou:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content:
            "notice.snackbar.errorMessage.accountIsNotExistedOrBelongToYou",
        })
      );
      return;
    // 更新帳號類型不符
    case ResponseError.accountTypeIsNotMatch:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountTypeIsNotMatch",
        })
      );
      return;
    // 帳號已經是主要帳號
    case ResponseError.accountAlreadyIsPrimary:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountAlreadyIsPrimary",
        })
      );
      return;
    // 帳號是主要帳號
    case ResponseError.accountIsPrimary:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.accountIsPrimary",
        })
      );
      return;
    // client 不存在
    case ResponseError.clientIsNotExists:
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.clientIsNotExists",
        })
      );
      return;
    default:
      break;
  }
}

export function* userSignInSaga({ payload }: PayloadAction<LoginPayload>) {
  try {
    const response: Response<any> = yield call(UserAPI.userLogin, payload);
    localStorage.setItem(
      "account-auth",
      JSON.stringify({
        token: response.data.token,
        current:
          (response.roles && Object.keys(response.roles)[0]) || "default",
      })
    );

    yield put(
      userActions.postUserSignInSuccess({
        ...response,
        current:
          (response.roles && Object.keys(response.roles)[0]) || "default",
      })
    );
    yield put(
      globalActions.getUserInfoSuccess({
        ...response,
        current:
          (response.roles && Object.keys(response.roles)[0]) || "default",
      })
    );
    yield call(getUserInfo);
    const agreements: Response<UserAgreementsResponse[]> = yield call(
      UserAPI.getUserAgreementsV2,
      { clientId: payload.clientId }
    );
    yield put(userActions.getUserAgreementsSuccess(agreements.data));
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.login.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.postUserSignInFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.login.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* userSignUpSaga({ payload }: PayloadAction<SignupRequest>) {
  try {
    const response: Response<any> = yield call(UserAPI.userSignup, payload);
    yield put(userActions.postUserSignupSuccess(response.data));
    const search = window.location.search;
    window.location.href = `/user/signin${search}`;
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.signup.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.postUserSignupFail());
    if (error.data?.errors[0].message) {
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.signup.invitationCode",
        })
      );
      return;
    }
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.signup.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* userGetOTPCodeSaga({
  payload,
}: PayloadAction<GetOTPCodeRequest>) {
  try {
    const response: Response<any> = yield call(UserAPI.getOtpCode, payload);
    yield put(userActions.getUserOTPCodeSuccess(response.data));
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.otp.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.getUserOTPCodeFail());
    // 簡訊商異常
    if (error?.status === 500) {
      yield put(userActions.setOTPCodeCountDown());
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.otp.ServerError",
        })
      );
      return;
    }
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.otp.error",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* userLogoutSaga({
  payload: { shouldRedirect = true },
}: PayloadAction<{
  shouldRedirect?: boolean;
}>) {
  try {
    const json = localStorage.getItem("account-auth");
    const accountAuth = JSON.parse(json as string) || {};

    yield call(
      UserAPI.userLogout,
      { token: accountAuth.token } || "session-token"
    );
    yield put(userActions.deleteUserLogoutSuccess());
    yield put(globalActions.getUserInfoFail());
    localStorage.removeItem("account-auth");
    const search = window.location.search;
    if (shouldRedirect) {
      window.location.href = `/user/signin${search}`;
    }
  } catch (error) {
    yield put(userActions.deleteUserLogoutFail());
    if (error) {
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.logout.fail",
        })
      );
      yield call(ErrorMessageSnackBar, error);
    }
  }
}

export function* resetPasswordSaga(
  action: PayloadAction<ResetPasswordRequest>
) {
  const { account, password, code } = action.payload;
  const data = {
    account,
    password,
    code,
  };
  try {
    const response: Response<any> = yield call(UserAPI.userResetPassword, data);
    yield put(userActions.postUserResetPasswordSuccess(response.data));
    const search = window.location.search;
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.resetPassword.success",
      })
    );
    yield delay(3000);
    window.location.href = `/user/signin${search}`;
  } catch (error: any) {
    yield put(userActions.postUserResetPasswordFail());
    if (error.data?.errors[0].message === ResponseError.validationRegex) {
      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.resetPassword.otp.error",
        })
      );
      return;
    }
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.resetPassword.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* updateUserProfileSaga({
  payload,
}: PayloadAction<UpdateUserProfileRequest>) {
  try {
    const response: Response<any> = yield call(
      UserAPI.updateUserProfile,
      payload
    );
    yield put(userActions.patchUserProfileSuccess(response.data));
    yield call(getUserInfo);
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.updateProfile.success",
      })
    );
  } catch (error) {
    yield put(userActions.patchUserProfileFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.updateProfile.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* getUserInfo() {
  try {
    const response: Response<any> = yield call(UserAPI.userInfo);
    const accountAuth = JSON.parse(localStorage.getItem("account-auth") || "");
    yield put(userActions.getUserInfoSuccess({ ...response, ...accountAuth }));
  } catch (error) {
    yield put(userActions.getUserInfoFail());
    localStorage.removeItem("account-auth");
    const search = window.location.search;
    window.location.href = `/user/signin${search}`;
  }
}

export function* addNewIdentifyAccountSaga({
  payload,
}: PayloadAction<AddNewIdentifyAccountRequest>) {
  try {
    const response: Response<any> = yield call(
      UserAPI.addNewIdentifyAccount,
      payload
    );
    yield put(userActions.postAddNewIdentifyAccountSuccess(response.data));
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.updateAccount.success",
      })
    );
  } catch (error) {
    yield put(userActions.postAddNewIdentifyAccountFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.updateAccount.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* updateUserAccountSaga({
  payload,
}: PayloadAction<UpdateAccountRequest>) {
  try {
    const response: Response<any> = yield call(
      UserAPI.updateUserAccount,
      payload
    );
    yield put(userActions.patchUserAccountSuccess(response.data));
    yield call(getUserInfo);
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.updateAccount.success",
      })
    );
  } catch (error) {
    yield put(userActions.patchUserAccountFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.updateAccount.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* validateThirdPartyAccountSaga({
  payload,
}: PayloadAction<ValidateThirdPartyAccountRequest>) {
  try {
    const response: Response<{ isValid: boolean }> = yield call(
      UserAPI.validateThirdPartyAccount,
      payload
    );
    yield put(userActions.postThirdPartyValidationSuccess(response.data));
    const { isValid } = response?.data;
    if (!isValid) {
      const isLogoutPage = window.location.pathname === "/sso/logout";
      const snackbarContent = isLogoutPage
        ? "notice.snackbar.thirdParty.logout.error"
        : "notice.snackbar.thirdParty.error";

      yield put(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: snackbarContent,
        })
      );
    }
  } catch (error) {
    yield put(userActions.postThirdPartyValidationFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.thirdParty.error",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* thirdPartyLoginPageMetadataSaga({
  payload,
}: PayloadAction<ThirdPartyLoginPageMetadataRequest>) {
  try {
    const response: Response<ThirdPartyLoginPageMetadataResponse> = yield call(
      UserAPI.thirdPartyLoginPageMetadata,
      payload
    );
    yield put(userActions.getThirdPartyLoginPageMetaSuccess(response.data));
  } catch (error) {
    yield put(userActions.getThirdPartyLoginPageMetaFail());
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* getUserAgreementsSaga({
  payload,
}: PayloadAction<UserAgreementRequest>) {
  try {
    const response: Response<UserAgreementsResponse[]> = yield call(
      UserAPI.getUserAgreementsV2,
      payload
    );
    yield put(userActions.getUserAgreementsSuccess(response.data));
  } catch (error) {
    yield put(userActions.getUserAgreementsFail());
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* updateUserAgreementsSaga({
  payload,
}: PayloadAction<UserAgreementRequest>) {
  try {
    const response: Response<UserAgreementsResponse[]> = yield call(
      UserAPI.postUserAgreementsV2,
      payload
    );
    const agreements: Response<UserAgreementsResponse[]> = yield call(
      UserAPI.getUserAgreementsV2,
      { clientId: payload.clientId }
    );
    yield put(userActions.getUserAgreementsSuccess(agreements.data));
    yield put(userActions.postUserAgreementsSuccess(response.data));
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.upgrade.success",
      })
    );
  } catch (error) {
    yield put(userActions.postUserAgreementsFail());
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* getClientAgreementsSaga({
  payload,
}: PayloadAction<UserAgreementRequest>) {
  try {
    const response: Response<UserAgreementsResponse[]> = yield call(
      UserAPI.getClientAgreements,
      payload
    );
    yield put(userActions.getClientAgreementsSuccess(response.data));
  } catch (error) {
    yield put(userActions.getClientAgreementsFail());
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* checkOAuthSaga({ payload }: PayloadAction<CheckOAuthRequest>) {
  try {
    const response: Response<CheckOAuthResponse> = yield call(
      UserAPI.checkOAuthAPI,
      payload
    );
    yield put(userActions.postCheckOAuthSuccess(response.data));
    if (!!response.data.redirectUrl.length) {
      window.location.href = response.data.redirectUrl;
    }
  } catch (error) {
    yield put(userActions.postCheckOAuthFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.oauth.error",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* ThirdPartyLogoutSaga({
  payload,
}: PayloadAction<ValidateThirdPartyAccountRequest>) {
  const { redirectUrl, shouldRedirect = true } = payload;
  try {
    const response: Response<{ isValid: boolean }> = yield call(
      UserAPI.validateThirdPartyAccount,
      payload
    );
    const { isValid } = response?.data;

    if (isValid) {
      const json = localStorage.getItem("account-auth");
      const accountAuth = JSON.parse(json as string) || {};

      yield call(
        UserAPI.userLogout,
        { token: accountAuth.token } || "session-token"
      );
      yield put(userActions.deleteThirdPartyLogoutSuccess());
      yield put(globalActions.getUserInfoFail());
      localStorage.removeItem("account-auth");

      if (shouldRedirect && redirectUrl) {
        window.location.href = redirectUrl;
      }
      return;
    }
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.thirdParty.logout.fail",
      })
    );
  } catch (error) {
    yield put(userActions.deleteThirdPartyLogoutFail());
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* changePasswordSaga(
  action: PayloadAction<ChangePasswordRequest>
) {
  try {
    const response: Response<any> = yield call(
      UserAPI.changePasswordAPI,
      action.payload
    );
    yield put(userActions.postChangePasswordSuccess(response.data));
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.changePassword.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.postChangePasswordFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.changePassword.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* changeContactEmailSaga(action: PayloadAction<any>) {
  try {
    const response: Response<any> = yield call(
      UserAPI.changeUserEmailAPI,
      action.payload
    );
    yield put(userActions.patchContactEmailSuccess(response.data));
    yield call(getUserInfo);
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.changeEmail.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.patchContactEmailFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.changeEmail.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* postContactEmailSaga(
  action: PayloadAction<PostUserEmailRequest>
) {
  try {
    const response: Response<any> = yield call(
      UserAPI.postUserEmailAPI,
      action.payload
    );
    yield put(userActions.postContactEmailSuccess(response.data));
    yield call(getUserInfo);
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "success",
        content: "notice.snackbar.changeEmail.success",
      })
    );
  } catch (error: any) {
    yield put(userActions.postContactEmailFail());
    yield put(
      globalActions.snackbarRequest({
        visible: true,
        variant: "error",
        content: "notice.snackbar.changeEmail.fail",
      })
    );
    yield call(ErrorMessageSnackBar, error);
  }
}

export function* setUserInfoSaga(action: PayloadAction<any>) {
  yield put(
    globalActions.getUserInfoSuccess({
      ...action.payload,
      current:
        (action.payload.roles && Object.keys(action.payload.roles)[0]) ||
        "default",
    })
  );
}

const sagas = [
  takeLatest(userActions.getUserInfoSuccess, setUserInfoSaga),
  takeLatest(userActions.postUserSignInRequest, userSignInSaga),
  takeLatest(userActions.postUserSignupRequest, userSignUpSaga),
  takeLatest(userActions.getUserOTPCodeRequest, userGetOTPCodeSaga),
  takeLatest(userActions.deleteUserLogoutRequest, userLogoutSaga),
  takeLatest(userActions.postUserResetPasswordRequest, resetPasswordSaga),
  takeLatest(userActions.patchUserProfileRequest, updateUserProfileSaga),
  takeLatest(userActions.getUserInfoRequest, getUserInfo),
  takeLatest(
    userActions.postAddNewIdentifyAccountRequest,
    addNewIdentifyAccountSaga
  ),
  takeLatest(userActions.patchUserAccountRequest, updateUserAccountSaga),
  takeLatest(
    userActions.postThirdPartyValidationRequest,
    validateThirdPartyAccountSaga
  ),
  takeLatest(
    userActions.getThirdPartyLoginPageMetaRequest,
    thirdPartyLoginPageMetadataSaga
  ),
  takeLatest(userActions.getUserAgreementsRequest, getUserAgreementsSaga),
  takeLatest(userActions.postUserAgreementsRequest, updateUserAgreementsSaga),
  takeLatest(userActions.postCheckOAuthRequest, checkOAuthSaga),
  takeLatest(userActions.deleteThirdPartyLogoutRequest, ThirdPartyLogoutSaga),
  takeLatest(userActions.postChangePasswordRequest, changePasswordSaga),
  takeLatest(userActions.patchContactEmailRequest, changeContactEmailSaga),
  takeLatest(userActions.postContactEmailRequest, postContactEmailSaga),
  takeLatest(userActions.getClientAgreementsRequest, getClientAgreementsSaga),
];
export default sagas;
