import React, { useEffect, useMemo } from "react";
import {
  Outlet,
  useNavigate,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import { getUser } from "@/reducers/states";
import useSelector from "@/hooks/useAppSelector";
import {
  Box,
  Toolbar,
  Typography,
  List,
  Drawer as MuiDrawer,
  Collapse,
  Icon,
  Grid,
} from "@mui/material";
import { Breadcrumb } from "@/uiComponents";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, Theme, useTheme } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CheckMainAccount from "@/components/CheckMainAccount";

interface DrawerProps {
  routes: any;
  componentPlugins?: {
    DrawerHeader?: React.FunctionComponent;
    DrawerBreadcrumb?: React.FC;
    DrawerLogout?: React.FC;
    HelmetHead?: React.FC;
  };
}

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  color: "black",
  boxShadow: "0px 4px 16px rgba(181, 181, 182, 0.2)",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  border: "none",
  boxShadow: "0px 4px 16px rgba(181, 181, 182, 0.2)",
}));

export const UserDrawer = (props: DrawerProps) => {
  const { routes, componentPlugins } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const MediaQuery768 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(768)
  );
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [routeCollapseOpen, setRouteCollapseOpen] = React.useState<{
    [key: string]: boolean;
  }>({});
  const location = useLocation();
  const matchedRoute = matchRoutes(routes, location) || [];

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const user = useSelector(getUser);

  return (
    <CheckMainAccount>
      <Box sx={{ display: "flex" }}>
        {componentPlugins && componentPlugins.HelmetHead && (
          <componentPlugins.HelmetHead />
        )}
        <AppBar position="fixed" open={drawerOpen}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={8}>
              <Toolbar>
                {componentPlugins && componentPlugins.DrawerHeader ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}>
                    {MediaQuery768 && (
                      <MenuIcon
                        sx={{
                          width: "35px",
                          height: "30px",
                          mr: "10px",
                          color: "#0055A4",
                        }}
                        onClick={() => setDrawerOpen((prev) => !prev)}
                      />
                    )}
                    <componentPlugins.DrawerHeader />
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" width={1}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{ flexGrow: 1 }}>
                      Drawer Layout
                    </Typography>
                  </Box>
                )}
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>
        <Main open={true}>
          <DrawerHeader />
          <Grid container justifyContent="center" spacing={1} bgcolor="#fff">
            <Grid
              item
              xs={12}
              sm={MediaQuery768 ? 12 : 10}
              md={MediaQuery768 ? 12 : 8}>
              <Box
                px={MediaQuery768 ? 0 : 3}
                bgcolor="#fff"
                display="flex"
                justifyContent="center"
                style={{ minHeight: "calc(100vh - 64px)", width: "100%" }}>
                <Box
                  sx={{
                    display: MediaQuery768 ? "none" : "",
                    width: drawerWidth,
                    height: "100vh",
                    flexShrink: 0,
                    background: "#ffffff",
                    boxShadow: "2px 0px 1px rgb(181 181 182 / 20%)",
                    paddingTop: MediaQuery768 ? "" : "64px",
                    "& .MuiList-root": {
                      marginTop: "3px",
                    },
                    " .MuiListItemButton-root": {
                      borderLeft: "6px solid #ffffff",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                    },
                    "	.Mui-selected": {
                      borderLeft: "6px solid #383838",
                      " .MuiTypography-root": {
                        fontWeight: "500",
                      },
                    },
                  }}>
                  <List>
                    {routes.map((route: RoutesType.Route) => {
                      return (
                        <React.Fragment key={route.path}>
                          <ListItem disablePadding>
                            <ListItemButton
                              selected={
                                !!matchedRoute.find(
                                  (item) => item.pathname === route.path
                                )
                              }
                              onClick={() => {
                                if (
                                  !route.children ||
                                  route.children.length <= 0
                                ) {
                                  navigate(route.path);
                                } else {
                                  setRouteCollapseOpen({
                                    ...routeCollapseOpen,
                                    [route.path]:
                                      !routeCollapseOpen[route.path],
                                  });
                                }
                              }}>
                              <ListItemIcon
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  ml="-5px">
                                  {typeof route.icon === "string" && (
                                    <Icon baseClassName="material-icons-outlined">
                                      {route.icon}
                                    </Icon>
                                  )}
                                  {typeof route.icon === "object" &&
                                    React.isValidElement(route.icon) &&
                                    route.icon}
                                  {(typeof route.icon === "function" ||
                                    (typeof route.icon === "object" &&
                                      !React.isValidElement(route.icon))) && (
                                    <route.icon />
                                  )}
                                </Box>
                              </ListItemIcon>
                              <ListItemText
                                primary={<FormattedMessage id={route.title} />}
                              />
                              {route.children && route.children.length > 0 ? (
                                !!routeCollapseOpen[route.path] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )
                              ) : (
                                <></>
                              )}
                            </ListItemButton>
                          </ListItem>
                          {route.children && route.children.length > 0 && (
                            <Collapse
                              in={!!routeCollapseOpen[route.path]}
                              timeout="auto"
                              unmountOnExit>
                              <List component="div" disablePadding>
                                {route.children.map(
                                  (childrenRoute: RoutesType.Route) => {
                                    return (
                                      <ListItemButton
                                        selected={
                                          !!matchedRoute.find(
                                            (item) =>
                                              item.pathname ===
                                              childrenRoute.path
                                          )
                                        }
                                        onClick={() =>
                                          navigate(childrenRoute.path)
                                        }
                                        key={childrenRoute.path}
                                        sx={{ pl: 9 }}>
                                        <ListItemText
                                          primary={
                                            <FormattedMessage
                                              id={childrenRoute.title}
                                            />
                                          }
                                        />
                                      </ListItemButton>
                                    );
                                  }
                                )}
                              </List>
                            </Collapse>
                          )}
                        </React.Fragment>
                      );
                    })}
                    {componentPlugins && componentPlugins.DrawerLogout && (
                      <componentPlugins.DrawerLogout />
                    )}
                  </List>
                </Box>
                {componentPlugins?.DrawerBreadcrumb ? (
                  <componentPlugins.DrawerBreadcrumb />
                ) : (
                  <Breadcrumb routes={routes} configs={{ enableIcon: true }} />
                )}
                <Box p={8} width="100%">
                  <Outlet />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Main>
        {/* 側邊欄 */}
        <MuiDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            " .MuiPaper-root": {
              width: "100vw",
              height: "calc(100vh - 73px)",
              top: "73px",
            },
            " .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
            " .MuiListItemButton-root": {
              borderLeft: "6px solid #ffffff",
            },
            "	.Mui-selected": {
              borderLeft: "6px solid #383838",
              " .MuiTypography-root": {
                fontWeight: "bold",
              },
            },
            " .MuiList-root": {
              paddingTop: "0px",
            },
          }}>
          <List>
            {routes.map((route: RoutesType.Route) => {
              return (
                <React.Fragment key={route.path}>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={
                        !!matchedRoute.find(
                          (item) => item.pathname === route.path
                        )
                      }
                      onClick={() => {
                        if (!route.children || route.children.length <= 0) {
                          navigate(route.path);
                          handleDrawerClose();
                        } else {
                          setRouteCollapseOpen({
                            ...routeCollapseOpen,
                            [route.path]: !routeCollapseOpen[route.path],
                          });
                        }
                      }}>
                      <ListItemIcon>
                        <Box>
                          {typeof route.icon === "string" && (
                            <Icon baseClassName="material-icons-outlined">
                              {route.icon}
                            </Icon>
                          )}
                          {typeof route.icon === "object" &&
                            React.isValidElement(route.icon) &&
                            route.icon}
                          {(typeof route.icon === "function" ||
                            (typeof route.icon === "object" &&
                              !React.isValidElement(route.icon))) && (
                            <route.icon />
                          )}
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={<FormattedMessage id={route.title} />}
                      />
                      {route.children && route.children.length > 0 ? (
                        !!routeCollapseOpen[route.path] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : (
                        <></>
                      )}
                    </ListItemButton>
                  </ListItem>
                  {route.children && route.children.length > 0 && (
                    <Collapse
                      in={!!routeCollapseOpen[route.path]}
                      timeout="auto"
                      unmountOnExit>
                      <List component="div" disablePadding>
                        {route.children.map(
                          (childrenRoute: RoutesType.Route) => {
                            return (
                              <ListItemButton
                                selected={
                                  !!matchedRoute.find(
                                    (item) =>
                                      item.pathname === childrenRoute.path
                                  )
                                }
                                onClick={() => navigate(childrenRoute.path)}
                                key={childrenRoute.path}
                                sx={{ pl: 9 }}>
                                <ListItemText
                                  primary={
                                    <FormattedMessage
                                      id={childrenRoute.title}
                                    />
                                  }
                                />
                              </ListItemButton>
                            );
                          }
                        )}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            })}
            {componentPlugins && componentPlugins.DrawerLogout && (
              <componentPlugins.DrawerLogout />
            )}
          </List>
        </MuiDrawer>
      </Box>
    </CheckMainAccount>
  );
};

export default UserDrawer;
