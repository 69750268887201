import { request, type ApiError } from "@/utils";
import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import { type AxiosResponse } from "axios";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import type { MeResponse } from "./oauthApi";

request.defaults.withCredentials = true;

// login
export interface LoginRequest {
  account: string;
  clientId: string;
  passwords: {
    algo: string;
    value: string;
  }[];
}
export interface LoginPayload extends LoginRequest {
  reCaptchaToken?: string;
}
export async function userLogin({
  account,
  passwords,
  clientId,
  reCaptchaToken,
}: LoginPayload): Promise<any> {
  const header = { "X-RECAPTCHA-TOKEN": reCaptchaToken || "" };
  return !!reCaptchaToken?.length
    ? request.post(
        `/v1/tokens`,
        { account, passwords, clientId },
        { headers: header }
      )
    : request.post(`/v1/tokens`, { account, passwords, clientId });
}

// logout
export interface ThirdPartyLogoutRequest {
  client_id: string;
  redirectUrl: string;
}
export async function userLogout({ token }: { token: string }) {
  return request.delete(`/v1/tokens/${token}`, {});
}

// get user info
export async function userInfo() {
  return request.get(`/v1/me`);
}

// signup
export interface SignupRequest {
  account: string;
  password: string;
  code: string;
  locale: string;
  clientId: string;
  /**
   * @deprecated no need to use this anymore
   */
  agreements?: {
    name: string;
    version: number;
  }[];
}
export async function userSignup({
  account,
  password,
  code,
  locale,
  clientId,
}: SignupRequest) {
  return request.post(`/v1/users`, {
    account,
    password,
    code,
    locale,
    clientId,
  });
}

export enum GetOTPCodeTypeEnum {
  REGISTER = "register",
  REGISTER_PASSWORD = "registerPassword",
  ACCOUNT_BINDING = "accountBinding",
  ACCOUNT_UPDATE = "accountUpdate",
  CONTACT_BINDING = "contactBinding",
  CONTACT_UPDATE = "contactUpdate",
  ACCOUNT_ASSOCIATE = "accountAssociate",
  EXCHANGE = "exchangeVerification",
}

export type GetOTPCodeType = `${GetOTPCodeTypeEnum}`;

// get OTP code
export interface GetOTPCodeRequest {
  account: string;
  target?: string;
  locale: string;
  type: GetOTPCodeType;
}
export async function getOtpCode({
  account,
  target = account,
  type,
  locale,
}: GetOTPCodeRequest) {
  return request.post(`/v1/users:send-verification`, {
    type,
    account,
    target,
    locale,
  });
}

// reset password
export interface ResetPasswordRequest {
  account: string;
  password: string;
  code: string;
}
export async function userResetPassword({
  account,
  password,
  code,
}: ResetPasswordRequest) {
  return request.post(`/v1/users:reset-password`, {
    account,
    password,
    code,
  });
}

// social login
export async function socialLogin({ provider, token, locale }: any) {
  return request.post(`/v1/tokens:with-social-login`, {
    provider,
    token,
    locale,
  });
}

// update user profile
export interface UpdateUserProfileRequest {
  name: string;
  gender: string;
  birthday: string;
  locale: string;
}
export async function updateUserProfile({
  name,
  gender,
  birthday,
  locale,
}: UpdateUserProfileRequest) {
  return request.patch(`/v1/me`, {
    name,
    gender,
    birthday,
    locale,
  });
}

export interface AddNewIdentifyAccountRequest {
  account: string;
  code: string;
}
export async function addNewIdentifyAccount({
  account,
  code,
}: AddNewIdentifyAccountRequest) {
  return request.post(`/v1/loginIdentifiers`, {
    account,
    code,
  });
}

// update existed login identify account
export interface UpdateAccountRequest extends AddNewIdentifyAccountRequest {
  id?: string;
}
export async function updateUserAccount({
  account,
  code,
  id,
}: UpdateAccountRequest) {
  return request.patch(`/v1/loginIdentifiers/${id}`, {
    account,
    code,
  });
}

// validate third party account
export interface ValidateThirdPartyAccountRequest {
  clientId?: string;
  redirectUrl?: string;
  shouldRedirect?: boolean;
}
export async function validateThirdPartyAccount({
  clientId,
  redirectUrl,
}: ValidateThirdPartyAccountRequest) {
  return request.post(`/v1/clients:validate`, {
    clientId,
    redirectUrl,
  });
}

// third party login page - show logo and social login button or not
export interface ThirdPartyLoginPageMetadataRequest {
  clientId: string;
}
export interface ThirdPartyLoginPageMetadataResponse {
  bannerUrl: string;
  logoUrl: string;
  vendorId: string;
  vendorName: string;
  socialLoginFlags: {
    google: boolean;
    facebook: boolean;
    apple: boolean;
    line: boolean;
  };
  customerSupportInfo?: {
    title?: string;
    message?: string;
    email?: string;
  };
  enhanceIdentityVerification?: boolean;
  forceEnhanceIdentityVerification?: boolean;
}
export async function thirdPartyLoginPageMetadata(
  { clientId }: ThirdPartyLoginPageMetadataRequest,
  options?: { signal?: AbortSignal }
): Promise<{
  data: ThirdPartyLoginPageMetadataResponse;
}> {
  return request.get(`/v1/clients/${clientId}/metadata`, {
    signal: options?.signal,
  });
}

// get user agreements
export interface UserAgreementRequest {
  clientId: string;
}

export type UserAgreementsResponse = {
  id: string;
  version: string;
  name: string;
  agreementHtmlUrl: string;
  agreementTextUrl: string;
  createdAt: string;
  updatedAt: string;
};

/**
 * @deprecated use v2 instead
 * @param params
 */
export async function getUserAgreements(params: UserAgreementRequest) {
  return request.get(`/v1/accepted-agreements`, { params });
}

export async function getUserAgreementsV2(params: UserAgreementRequest) {
  return request.post(`/v1/accepted-agreements:get-latest-unaccepted`, {
    clientId: params.clientId,
  });
}

export async function getClientAgreements({ clientId }: UserAgreementRequest) {
  return request.post(`/v1/clients/${clientId}/agreements:get-latest`);
}

export enum AGREEMENTS {
  EndUserLicense = "EndUserLicense",
  PrivacyPolicy = "PrivacyPolicy",
  MainTerms = "MainTerms",
}

export type AgreementName = `${AGREEMENTS}`;

export interface PostUserAgreementsRequest {
  clientId: string;
  /**
   * @deprecated we don't use this anymore
   */
  agreements?: {
    name: AGREEMENTS.MainTerms;
    version: number;
  }[];
}
/**
 * @deprecated we don't use this anymore
 * @param agreements
 */
export async function postUserAgreements(
  agreements: PostUserAgreementsRequest
) {
  return request.post(`/v1/accepted-agreements:batch-upsert`, {
    ...agreements,
  });
}

export async function postUserAgreementsV2(params: UserAgreementRequest) {
  return request.post(`/v1/accepted-agreements:accept-latest`, {
    clientId: params.clientId,
  });
}

// check oauth for redirect to third party page
export type CheckOAuthRequest = string;
export interface CheckOAuthResponse {
  redirectUrl: string;
}

export async function checkOAuthAPI(params: CheckOAuthRequest) {
  return request.get(`/v1/oauth/authorize?${params}`);
}

// change password
export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export async function changePasswordAPI(data: ChangePasswordRequest) {
  return request.post(`/v1/me:change-password`, data);
}

type ChangeUserEmailRequest = {
  contact: string;
  code: string;
  id: string;
};

export type PostUserEmailRequest = {
  contact: string;
  code: string;
};

// update user email
export async function changeUserEmailAPI(data: ChangeUserEmailRequest) {
  return request.patch(`/v1/contacts/${data.id}`, {
    contact: data.contact,
    code: data.code,
  });
}

export async function postUserEmailAPI(data: PostUserEmailRequest) {
  return request.post(`/v1/contacts`, {
    contact: data.contact,
    code: data.code,
  });
}

export const UserAPI = {
  userLogin,
  userLogout,
  userInfo,
  userSignup,
  getOtpCode,
  userResetPassword,
  socialLogin,
  updateUserProfile,
  addNewIdentifyAccount,
  updateUserAccount,
  validateThirdPartyAccount,
  thirdPartyLoginPageMetadata,
  getUserAgreementsV2,
  postUserAgreementsV2,
  getClientAgreements,
  checkOAuthAPI,
  changePasswordAPI,
  changeUserEmailAPI,
  postUserEmailAPI,
};

export const useGetOtpCode = (
  opts?: UseMutationOptions<AxiosResponse<void>, ApiError, GetOTPCodeRequest>
) => {
  return useMutation<AxiosResponse<void>, ApiError, GetOTPCodeRequest>({
    ...opts,
    mutationFn: (dto) => {
      return UserAPI.getOtpCode(dto);
    },
  });
};

export const useUpdateUserPhone = (
  opts?: UseMutationOptions<AxiosResponse<any>, ApiError, UpdateAccountRequest>
) => {
  return useMutation<AxiosResponse<any>, ApiError, UpdateAccountRequest>({
    ...opts,
    mutationFn: (dto) => {
      return dto.id
        ? UserAPI.updateUserAccount(dto) // `/v1/loginIdentifiers/${id}`
        : UserAPI.addNewIdentifyAccount(dto); // `/v1/loginIdentifiers`
    },
  });
};

/**
 * @deprecated Use `useGetClientMeta` instead
 */
export const useGetThirdPartyLoginPageMeta = () => {
  const [searchParams] = useSearchParams({
    client_id: "",
  });
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const clientId =
  //     searchParams.get("client_id") ||
  //     (!IS_PRODUCTION && window.Config.CLIENT_ID);
  //   if (clientId) {
  //     dispatch(userActions.getThirdPartyLoginPageMetaRequest({ clientId }));
  //   }
  // }, [searchParams]);
};

export const useGetClientMeta = ({
  payload,
  options,
}: {
  payload: ThirdPartyLoginPageMetadataRequest;
  options?: Omit<
    UseQueryOptions<{ data: ThirdPartyLoginPageMetadataResponse }, ApiError>,
    "queryKey" | "queryFn"
  >;
}) => {
  return useQuery<{ data: ThirdPartyLoginPageMetadataResponse }, ApiError>({
    ...options,
    queryKey: ["clientMeta", payload.clientId],
    queryFn: ({ signal }) => thirdPartyLoginPageMetadata(payload, { signal }),
  });
};

export const useQueryMe = (
  options?: Omit<UseQueryOptions<MeResponse, ApiError>, "queryKey" | "queryFn">
) => {
  return useQuery<MeResponse, ApiError>({
    ...options,
    queryKey: ["me"],
    queryFn: userInfo,
  });
};
