import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import ProjectConfig from "./project.config.json";
import { Provider } from "react-redux";
import reducers from "./reducers";
import appSagas from "./sagas";
import RoutesJSON from "./router/routes.json";
import { loadableComponent } from "./utils/loadableComponent";
import LayoutLoading from "./components/LayoutLoading";
import PageLoading from "./components/PageLoading";
import locales from "./locales";
import createStore from "./reducers/store";
import * as Sentry from "@sentry/react";
import { reactRouterSentry } from "@/utils/sentry.util";

const sentryEnv = ProjectConfig.sentry.env;

Sentry.init({
  dsn: window.Config.SENTRY_DSN,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: window.Config.NODE_ENV,
  integrations: [reactRouterSentry],
  enabled: sentryEnv.includes(window.Config.NODE_ENV),
});

const routes = loadableComponent({
  routes: RoutesJSON,
  LayoutLoading: <LayoutLoading />,
  PageLoading: <PageLoading />,
});
const store = createStore({
  appConfig: ProjectConfig,
  appReducers: reducers,
  appSagas,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App {...{ config: ProjectConfig, routes, appLocales: locales }} />
    </Provider>
  </React.StrictMode>
);
