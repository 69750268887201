import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "@/reducers/states";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => {
  return {
    logoImage: {
      width: "300px",
      height: "100%",
    },
  };
});

const SignLogo = () => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const [logoImage, setLogoImage] = useState("");

  useEffect(() => {
    const hasThirdPartyLogo =
      user.thirdPartyLoginPageMetadata.loaded &&
      !!user.thirdPartyLoginPageMetadata.data?.logoUrl?.length;

    if (hasThirdPartyLogo) {
      setLogoImage(user.thirdPartyLoginPageMetadata.data?.logoUrl);
    }
  }, [user.thirdPartyLoginPageMetadata]);

  return (
    <Box>
      <img
        className={classes.logoImage}
        src={
          !!logoImage.length ? logoImage : "/assets/logos/logo_luxgen_en.png"
        }
        alt="logo"
      />
    </Box>
  );
};

export default React.memo(SignLogo);
