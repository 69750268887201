import React from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ListItem, ListItemButton, ListItemIcon, Theme } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { userActions } from "@/reducers/user/user.slice";
import useMediaQuery from "@mui/material/useMediaQuery";

const DrawerLogout = () => {
  const dispatch = useDispatch();
  const MediaQuery768 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(768)
  );
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => {
          dispatch(userActions.deleteUserLogoutRequest({}));
        }}>
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: MediaQuery768 ? "flex-start" : "center",
          }}>
          <LogoutIcon />
        </ListItemIcon>
        <FormattedMessage id="title.logout" />
      </ListItemButton>
    </ListItem>
  );
};

export default React.memo(DrawerLogout);
