import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { userActions } from "@/reducers/user/user.slice";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  groupImage: {
    width: "160px",
    height: "70px",
  },
}));

const DrawerHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(userActions.getUserInfoRequest());
  }, [dispatch, location.pathname]);

  return (
    <Box
      display="flex"
      alignItems="center"
      width={1}
      fontWeight="fontWeightBold"
      sx={{ color: "#0055A4" }}>
      <img
        src="/assets/logos/yulon-logo.svg"
        className={classes.groupImage}
        alt="yulon-logo"
      />
      <Typography mr={1.5}>|</Typography>
      <Typography
        noWrap
        sx={{ flexGrow: 1, fontSize: "18px", fontWeight: "900" }}>
        <FormattedMessage id="title.profile.yulon" />
      </Typography>
    </Box>
  );
};

export default DrawerHeader;
