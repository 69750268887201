import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export const Default = () => {
  return (
    <Box
      className="w-full min-h-screen flex justify-center items-center"
      sx={{
        background: "linear-gradient(180deg, #FFF 0%, #E2E2E3 100%)",
      }}>
      <Box
        p={3}
        className="container mx-auto flex flex-col justify-center items-center">
        <Outlet />
      </Box>
    </Box>
  );
};

export default Default;
