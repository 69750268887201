import { Helmet } from "react-helmet";
import _ from "lodash";
import { injectIntl } from "react-intl";

interface HelmetHeadProps {
  pageTitle?: string;
  intl: any;
  fontFamily?: {
    name: string;
    value: string;
  }[];
}

const LayoutHelmetHead = injectIntl((props: HelmetHeadProps) => {
  const { intl, fontFamily, pageTitle } = props;

  return (
    <Helmet>
      <title>
        {intl.formatMessage({ id: pageTitle || `project.website.title` })}
      </title>
      <meta
        name="description"
        content={intl.formatMessage({ id: `project.website.description` })}
      />
      <meta
        property="og:image:alt"
        content={intl.formatMessage({ id: `project.website.title` })}
      />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: `project.website.title` })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: `project.website.description` })}
      />
    </Helmet>
  );
});

export default LayoutHelmetHead;
