import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  type AxiosError,
} from "axios";
import cookie from "js-cookie";

export interface Response<T> {
  data: T;
  status: number;
  statusText: string;
  roles?: any;
}

export interface ErrorResponse {
  errors: Array<{
    field: string;
    message: string;
  }>;
}

export interface ApiError extends Omit<AxiosError<ErrorResponse>, "response"> {
  data: ErrorResponse;
}

const errorHandler = (error: { response: any }): void => {
  const { response } = error;
  throw response;
};

export const request = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  /**
   * **這裡必需刪除 `X-Xsrf-Token`，否則會 `CORS`**
   * 主因是現在金流模組的後端會把 csrf token set 到 sso 的 cookie 來
   * 並且現今前端使用的是 axios，axios 會自動把 `XSRF-TOKEN` 帶到 request headers 裡
   */
  xsrfCookieName: "YOU-SHOULDNT-BE-HERE",
});

request.interceptors.request.use(function (config) {
  const accountAuth = JSON.parse(localStorage.getItem("account-auth") || "{}");

  /**
   * **這裡必需刪除 `X-Xsrf-Token`，否則會 `CORS`**
   * 主因是現在金流模組的後端會把 csrf token set 到 sso 的 cookie 來
   * 並且現今前端使用的是 axios，axios 會自動把 `XSRF-TOKEN` 帶到 request headers 裡
   */
  // delete config.headers["X-Xsrf-Token"]; // not working
  // cookie.remove("XSRF-TOKEN", {
  //   domain: ".sys-beta.yulon-group.com",
  // });

  config.url =
    window.Config.NODE_ENV === "local"
      ? `/proxy-api${config.url}`
      : `${window.Config.API_HOST}${config.url}`;

  if (accountAuth.token) {
    config.headers["Authorization"] = `Bearer ${accountAuth.token}`;
  }
  return config;
});

request.interceptors.response.use(function (response: AxiosResponse) {
  return response.data;
}, errorHandler);

export default request;
