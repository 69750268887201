import { alpha, createTheme } from "@mui/material/styles";
import { AlertClassKey, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    default: string;
    paper: string;
    surface: string;
    onSurface: string;
    top: string;
  }
  interface TypeText {
    darkPrimary1: string;
    darkPrimary2: string;
    darkPrimary3: string;
    darkPrimary4: string;
    lightPrimary1: string;
    lightPrimary2: string;
    lightPrimary3: string;
    lightPrimary4: string;
    gary1: string;
    gary2: string;
    gary3: string;
    gary4: string;
    gary5: string;
    gary6: string;
    gary7: string;
    gary8: string;
    gary9: string;
    gary10: string;
  }
  interface Palette {
    default: Palette["primary"];
    border: Palette["primary"];
    background: Palette["background"];
    text: Palette["text"];
    facebook: PaletteColorOptions;
    line: PaletteColorOptions;
    apple: PaletteColorOptions;
  }
  interface PaletteOptions {
    default?: PaletteOptions["primary"];
    border?: PaletteOptions["primary"];
    background?: Partial<TypeBackground>;
    text?: Partial<TypeText>;
    facebook?: PaletteColorOptions;
    line?: PaletteColorOptions;
    apple?: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    facebook: true;
    line: true;
    apple: true;
  }
}

declare module "@mui/material/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#0055A4",
      light: "#5181d6",
      dark: "#002d74",
      contrastText: "#fff",
    },
    facebook: {
      main: "#1877F2",
    },
    line: {
      main: "#06C755",
    },
    apple: {
      main: "#000000",
    },
    secondary: {
      main: "#B5B5B6",
      light: "#D9D9D9",
      dark: "#383838",
      contrastText: "#fff",
    },
    default: {
      main: "#434858",
      contrastText: "#fff",
    },
    success: {
      main: "#4DCC76",
    },
    warning: {
      main: "#F8BC44",
    },
    error: {
      main: "#ED5454",
    },
    info: {
      main: "#2D62F2",
    },
    divider: "#4b546a",
    border: {
      main: "#4b546a",
      light: "#60c2ff",
      dark: "#62657b",
    },
    background: {
      default: "#18181F",
      paper: "#ffffff",
      surface: "#1D1F29",
      onSurface: "#242733",
      top: "#353A4C",
    },
    text: {
      primary: "#383838",
      secondary: "#aaa",
      darkPrimary1: "rgba(255,255,255,1)",
      darkPrimary2: "rgba(255,255,255,0.85)",
      darkPrimary3: "rgba(255,255,255,0.65)",
      darkPrimary4: "rgba(255,255,255,0.25)",
      lightPrimary1: "rgba(0,0,0,1)",
      lightPrimary2: "rgba(0,0,0,0.85)",
      lightPrimary3: "rgba(0,0,0,0.65)",
      lightPrimary4: "rgba(0,0,0,0.25)",
      gary1: "#EFEFEF",
      gary2: "#E2E2E3",
      gary3: "#C7C8C8",
    },
    action: {
      disabled: alpha("#383838", 0.25),
    },
  },
});

export default defaultTheme;
