import React, { type FC, type ReactNode, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { cn, isUrl, convertGitTagVersion } from "@/utils";
import { useGetClientMeta } from "@/services/userApi";
import logo from "@/assets/images/yulon-logo.svg";
import { useDispatch } from "react-redux";
import { globalActions } from "@/reducers/global.slice";
import { FormattedMessage } from "react-intl";
import { useOauthClientStore } from "@/stores/oauth.store";
import { Image } from "@nextui-org/image";
import ProjectConfig from "@/project.config.json";

interface BannerProps {
  hasBannerCallback: () => void;
}
interface UserProps {
  componentPlugins?: {
    SignLogo?: React.FC;
    HelmetHead?: React.FC;
    UserBanner?: React.FC<BannerProps>;
  };
}

interface Props {
  children?: ReactNode;
  to?: string;
  shouldRedirect?: boolean;
}

const displayClientBanner = ProjectConfig.client.displayBanner;

const Callback: FC<Props> = ({
  children,
  to = "/oauth/login",
  shouldRedirect,
}) => {
  const location = useLocation();
  if (shouldRedirect) {
    return <Navigate to={to} state={{ from: location }} />;
  }
  return <>{children}</>;
};

const Logo: FC<{ url?: string | null; isLoading?: boolean }> = ({
  url = "裕隆集團會員中心",
  isLoading,
}) => {
  if (!displayClientBanner) {
    return (
      <Typography variant="h5" className="text-[#666666] text-center py-5">
        裕隆集團會員中心
      </Typography>
    );
  }
  if (isLoading) {
    return (
      <Image
        loading="eager"
        width={200}
        height={85}
        isLoading
        src={logo}
        className="h-auto w-[200px]"
        alt="yulon logo"
      />
    );
  } else if (isUrl(url)) {
    return (
      <Image
        loading="eager"
        width={200}
        height={85}
        src={url!}
        className="h-auto w-[200px] object-cover"
        alt="yulon logo"
      />
    );
  }

  return (
    <Typography variant="h5" className="text-[#666666] text-center py-5">
      {url?.slice(0, 10)}
    </Typography>
  );
};

export const User = (props: UserProps) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { clientInfo } = useOauthClientStore();
  const { data, isLoading, isError, error } = useGetClientMeta({
    payload: {
      clientId:
        searchParams.get("client_id") ||
        clientInfo.clientId ||
        window.Config.CLIENT_ID,
    },
    options: {
      retry: false,
    },
  });

  useEffect(() => {
    if (isError && error) {
      dispatch(
        globalActions.snackbarRequest({
          visible: true,
          variant: "error",
          content: "notice.snackbar.errorMessage.clientIsNotExists",
        })
      );
    }
  }, [isError, error]);

  return (
    <Callback
      to={`/oauth/login${location.search}`}
      shouldRedirect={
        (searchParams.get("display") === "social_page" &&
          location.pathname.includes("/user/signin")) ||
        (location.pathname.includes("/oauth/authorize") &&
          !!searchParams.get("provider") &&
          searchParams.get("display") === "social_page" &&
          searchParams.get("skip_auth") !== "true")
      }>
      {props.componentPlugins?.HelmetHead && (
        <props.componentPlugins.HelmetHead />
      )}
      <main className="min-w-screen flex bg-[#EFEFEF]">
        <div
          style={{
            backgroundImage:
              isUrl(data?.data.bannerUrl) && displayClientBanner && !isLoading
                ? `url(${data?.data.bannerUrl})`
                : undefined,
          }}
          className={cn(
            "hidden min-h-screen w-1/2 bg-cover bg-center lg:block",
            ((!isUrl(data?.data.bannerUrl) && !isLoading) ||
              !displayClientBanner) &&
              "bg-[url('/src/assets/images/main-bg.png')]",
            isLoading && "bg-[#EFEFEF]"
          )}
        />
        <div className="flex min-h-screen w-full flex-col items-center justify-center lg:w-1/2">
          <div className="mb-[17px] mt-5 flex items-center">
            <Logo url={data?.data.logoUrl} isLoading={isLoading} />
          </div>
          <div className="flex w-full items-center justify-center">
            <Outlet />
          </div>
          <Box mt={4} mb={1} sx={{ letterSpacing: "0.15px" }}>
            <FormattedMessage id="description.slogan" />
          </Box>
          <Box>
            <Image
              width={200}
              className="h-auto w-[200px]"
              src="/assets/logos/yulon-logo.svg"
              alt="yulon-logo"
            />
          </Box>
          <Typography
            variant="body2"
            className="mt-6 text-center text-[#666666]">
            {convertGitTagVersion()}
          </Typography>
        </div>
      </main>
    </Callback>
  );
};

// const Index = () => {
//   const [searchParams] = useSearchParams();
//   return (
//     <Callback
//       to={`/oauth/login${location.search}`}
//       shouldRedirect={
//         (searchParams.get("display") === "social_page" &&
//           location.pathname.includes("/user/signin")) ||
//         (location.pathname.includes("/oauth/authorize") &&
//           !!searchParams.get("provider") &&
//           searchParams.get("display") === "social_page" &&
//           searchParams.get("skip_auth") !== "true")
//       }>
//       <User />
//     </Callback>
//   );
// };

export default User;
