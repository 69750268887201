import { useEffect, useState, FC } from "react";
import { CssBaseline, LinearProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import BuildRouter from "./router/BuildRouter";
import { BrowserRouter } from "react-router-dom";
import * as Styles from "./styles";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { globalActions } from "./reducers/global.slice";
import { getGlobal } from "./reducers/states";
import plugins from "@/plugins";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import locales from "./locales";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const AppPlugins = plugins["App"];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

type AppProps = {
  config: {
    themeName: string;
    [key: string]: any;
  };
  routes: RoutesType.Route[];
  appLocales: any;
};

export const App: FC<AppProps> = (props: AppProps) => {
  const { config, routes, appLocales } = props;
  const dispatch = useDispatch();
  const { locale, startUp } = useSelector(getGlobal);
  const styles: any = Styles;
  const theme = config.themeName
    ? styles[config.themeName]
    : styles.defaultTheme;
  const [internalQueryClient] = useState(() => queryClient);

  const i8nMessages = locales;

  useEffect(() => {
    dispatch(globalActions.startUpRequest());
  }, []);

  return (
    <IntlProvider locale="en" messages={i8nMessages?.[locale ?? "zh_tw"] || {}}>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {startUp.loading && <LinearProgress />}
          {startUp.loaded && (
            <QueryClientProvider client={internalQueryClient}>
              <BrowserRouter>
                <BuildRouter routes={routes} appConfig={config} />
                <AppPlugins />
                <ReactQueryDevtools initialIsOpen={false} />
              </BrowserRouter>
            </QueryClientProvider>
          )}
        </>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
