import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getUser } from "@/reducers/states";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  userBannerContainer: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  banner: {
    verticalAlign: "middle",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    minHeight: "830px",
    [theme.breakpoints.down(1480)]: {
      height: "100vh",
    },
  },
}));

interface BannerProps {
  hasBannerCallback: () => void;
}

const UserBanner = ({ hasBannerCallback }: BannerProps) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const [bannerImage, setBannerImage] = useState<string>("");

  // 判斷是否有 banner url 如果有就執行 hasBannerCallback 讓 layout 顯示 banner
  useEffect(() => {
    if (
      !!user.thirdPartyLoginPageMetadata.data?.bannerUrl?.length &&
      user.thirdPartyLoginPageMetadata.loaded
    ) {
      setBannerImage(user.thirdPartyLoginPageMetadata.data.bannerUrl);
      if (typeof hasBannerCallback === "function") {
        hasBannerCallback();
      }
    }
  }, [hasBannerCallback, user]);

  if (!bannerImage.length) return null;

  return (
    <Box className={classes.userBannerContainer}>
      <img className={classes.banner} src={bannerImage} alt="banner" />
    </Box>
  );
};

export default UserBanner;
