import LayoutHelmetHead from "@/components/LayoutHelmetHead";

const HelmetHead = () => {
  return (
    <>
      <LayoutHelmetHead />
    </>
  );
};

export default HelmetHead;
