import { useSelector, useDispatch } from "react-redux";
import { globalActions } from "@/reducers/global.slice";
import { Dialog, Snackbar } from "@/uiComponents";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userActions } from "@/reducers/user/user.slice";
import LayoutHelmetHead from "@/components/LayoutHelmetHead";

const AppPlugins = () => {
  const global = useSelector((state: any) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [client_id] = useState(searchParams.get("client_id") || "");
  const [redirect_uri] = useState(searchParams.get("redirect_uri") || "");

  useEffect(() => {
    if (location.pathname === "/sso/logout") {
      dispatch(
        userActions.deleteThirdPartyLogoutRequest({
          clientId: client_id,
          redirectUrl: redirect_uri,
        })
      );
    }
  }, [client_id, dispatch, location.pathname, redirect_uri]);

  return (
    <>
      <LayoutHelmetHead />
      <Dialog
        options={global.dialog}
        configs={{
          dialogBorder: false,
          actionFullWidth: true,
          actionColumn: true,
          actionColumnReverse: false,
          buttonSize: "large",
        }}
        onClose={() => {
          dispatch(globalActions.toogleDialog({ visible: false }));
        }}
        onConfirm={() => {
          if (typeof global.dialog.confirmHandle === "function") {
            global.dialog.confirmHandle(dispatch, navigate);
          } else {
            dispatch(globalActions.toogleDialog({ visible: false }));
          }
        }}
        onCancel={() => {
          if (typeof global.dialog.cancelHandle === "function") {
            global.dialog.cancelHandle(dispatch, navigate);
          } else {
            dispatch(globalActions.toogleDialog({ visible: false }));
          }
        }}
      />
      <Snackbar
        options={global.snackbar}
        configs={{
          onlyUseDefaultBackground: false,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          autoHideDuration: 3000,
          contentColor: "#fff",
        }}
        onClose={() => {
          dispatch(globalActions.snackbarRequest({ visible: false }));
        }}
      />
    </>
  );
};

export default AppPlugins;
