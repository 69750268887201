import { create } from "zustand";
import { persist, createJSONStorage, StateStorage } from "zustand/middleware";
import {
  OAUTH_PROVIDER_LOCAL_STORAGE_KEY,
  OAUTH_CLIENT_INFO_LOCAL_STORAGE_KEY,
} from "@/constants";
import Cookies from "js-cookie";

export const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    console.log(name, "has been retrieved");
    return localStorage.getItem(name) || Cookies.get(name) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    console.log(name, "with value", value, "has been saved");
    localStorage.setItem(name, value);
    Cookies.set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    console.log(name, "has been deleted");
    localStorage.removeItem(name);
    Cookies.remove(name);
  },
};

export const useOauthClientStore = create<{
  clientInfo: OauthLoginStorage;
  setClientInfo: (clientInfo: OauthLoginStorage) => void;
  removeClientInfo: () => void;
}>()(
  persist(
    (set, get) => ({
      clientInfo: {
        clientId: null,
        redirectUri: null,
        responseType: null,
        state: null,
        btoa: null,
        appleFormPost: false,
      },
      setClientInfo: (clientInfo: OauthLoginStorage) => {
        set({ clientInfo });
      },
      removeClientInfo: () =>
        storage.removeItem(OAUTH_CLIENT_INFO_LOCAL_STORAGE_KEY),
    }),
    {
      name: OAUTH_CLIENT_INFO_LOCAL_STORAGE_KEY,
      storage: createJSONStorage(() => storage),
    }
  )
);

/**
 * @deprecated
 */
export const useOauthProviderStore = create<
  OauthProviderStorage & {
    setOauthProvider: (provider: string) => void;
    removeOauthProvider: () => void;
  }
>()(
  persist(
    (set, get) => ({
      provider: null,
      setOauthProvider: (provider: string) => {
        set({ provider });
      },
      removeOauthProvider: () =>
        storage.removeItem(OAUTH_PROVIDER_LOCAL_STORAGE_KEY),
    }),
    {
      name: OAUTH_PROVIDER_LOCAL_STORAGE_KEY,
      storage: createJSONStorage(() => storage),
    }
  )
);
