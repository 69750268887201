export * from "./request";
export * from "./loadableComponent";
export * from "./validator";
export * from "./setGTM";
export * from "./cn";
export { default as setSearchParams } from "./setSearchParams";

export const isUrl = (url?: string | null) => {
  try {
    // @ts-expect-error
    const _url = new URL(url);
    return _url.protocol === "https:" || _url.protocol === "http:";
  } catch (error) {
    return false;
  }
};

export const convertGitTagVersion = () => {
  const version = window.Config.IMAGE_VERSION;

  if (version === "APP_IMAGE_VERSION") {
    return "local";
  }
  if (version === "develop") {
    return version;
  }
  return `v${version}`;
};
