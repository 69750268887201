import * as React from "react";
import {
  Outlet,
  useNavigate,
  useLocation,
  matchRoutes,
  useSearchParams,
} from "react-router-dom";
import { Box, Toolbar, IconButton, Typography, Grid } from "@mui/material";
import { Breadcrumb } from "@/uiComponents";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CheckMainAccount from "@/components/CheckMainAccount";
import { setSearchParams } from "@/utils";
import { getUser } from "@/reducers/states";
import useSelector from "@/hooks/useAppSelector";

interface ThirdPartyDrawerProps {
  routes: any;
  componentPlugins?: {
    ThirdPartyDrawerHeader?: React.FunctionComponent;
    DrawerBreadcrumb?: React.FC;
    HelmetHead?: React.FC;
  };
}

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  color: "black",
  boxShadow: "0px 4px 16px rgba(181, 181, 182, 0.2)",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  border: "none",
  boxShadow: "0px 4px 16px rgba(181, 181, 182, 0.2)",
}));

export const ThirdPartyDrawer = (props: ThirdPartyDrawerProps) => {
  const { routes, componentPlugins } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [routeCollapseOpen, setRouteCollapseOpen] = React.useState<{
    [key: string]: boolean;
  }>({});
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const matchedRoute = matchRoutes(routes, location) || [];

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const user = useSelector(getUser);

  return (
    <CheckMainAccount
      to={setSearchParams(
        {
          is_third_party: "true",
          // base64 encode the search params
          btoa: btoa(JSON.stringify(searchParams)),
          client_id: searchParams.get("client_id"),
          redirect_uri: searchParams.get("redirect_uri"),
        },
        {
          baseUrl: "/callback/error",
        }
      )}>
      <Box sx={{ display: "flex" }}>
        {componentPlugins && componentPlugins.HelmetHead && (
          <componentPlugins.HelmetHead />
        )}
        <AppBar position="fixed" open={drawerOpen}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={10} md={8}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}>
                  <MenuIcon />
                </IconButton>
                {componentPlugins && componentPlugins.ThirdPartyDrawerHeader ? (
                  <componentPlugins.ThirdPartyDrawerHeader />
                ) : (
                  <Box display="flex" alignItems="center" width={1}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{ flexGrow: 1 }}>
                      Drawer Layout
                    </Typography>
                  </Box>
                )}
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>
        <Main open={drawerOpen}>
          <DrawerHeader />
          <Grid container justifyContent="center" spacing={1} bgcolor="#fff">
            <Grid item xs={12} sm={10} md={8}>
              <Box
                p={3}
                style={{ minHeight: "calc(100vh - 64px)" }}
                display="flex"
                justifyContent="center">
                {componentPlugins?.DrawerBreadcrumb ? (
                  <componentPlugins.DrawerBreadcrumb />
                ) : (
                  <Breadcrumb routes={routes} configs={{ enableIcon: true }} />
                )}
                <Box p={5} width="100%">
                  <Outlet />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Main>
      </Box>
    </CheckMainAccount>
  );
};

export default ThirdPartyDrawer;
