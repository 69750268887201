import { type FC, useMemo, type ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "@/reducers/user/user.slice";
import { getUser, getGlobal } from "@/reducers/states";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

interface Props {
  children?: ReactNode;
  to?: string;
  shouldRedirect?: boolean;
}

const Callback: FC<Omit<Props, "fallback">> = ({
  children,
  to = "/callback/error",
  shouldRedirect,
}) => {
  const location = useLocation();
  if (shouldRedirect) {
    return <Navigate to={to} state={{ from: location }} />;
  }
  return <>{children}</>;
};

const CheckMainAccount: FC<Props> = ({ children, to, shouldRedirect }) => {
  const user = useSelector(getUser);
  const mainAccount = useMemo(
    () =>
      user.user.data.data?.accounts?.filter(
        (item) => item.type === "mobile"
      )[0],
    [user]
  );
  return (
    <Callback to={to} shouldRedirect={shouldRedirect ?? !mainAccount}>
      {children}
    </Callback>
  );
};

export default CheckMainAccount;
