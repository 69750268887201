import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  groupImage: {
    width: "160px",
    height: "70px",
  },
}));

export const Basic = () => {
  const classes = useStyles();
  return (
    <main className="w-screen min-h-screen bg-white relative">
      <Box
        display="flex"
        alignItems="center"
        width={1}
        fontWeight="fontWeightBold"
        className="sticky top-0 bg-white z-10"
        sx={{ color: "#0055A4" }}>
        <img
          src="/assets/logos/yulon-logo.svg"
          className={classes.groupImage}
          alt="yulon-logo"
        />
        <Typography mr={1.5}>|</Typography>
        <Typography
          noWrap
          sx={{ flexGrow: 1, fontSize: "18px", fontWeight: "900" }}>
          <FormattedMessage id="title.profile.yulon" />
        </Typography>
      </Box>
      <Outlet />
    </main>
  );
};

export default Basic;
