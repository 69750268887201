import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  groupImage: {
    width: "160px",
    height: "70px",
  },
}));

const ThirdPartyDrawerHeader = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={1}
      fontWeight="fontWeightBold"
      sx={{ color: "#0055A4" }}>
      <img
        src="/assets/logos/yulon-logo.svg"
        className={classes.groupImage}
        alt="yulon-logo"
      />
      <Typography mr={1.5}>|</Typography>
      <Typography noWrap sx={{ fontSize: "18px", fontWeight: "900" }}>
        <FormattedMessage id="title.profile.yulon" />
      </Typography>
    </Box>
  );
};

export default ThirdPartyDrawerHeader;
