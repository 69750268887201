import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  Routes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export const reactRouterSentry = Sentry.reactRouterV6BrowserTracingIntegration({
  useEffect: useEffect,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
});

export const sessionReplay = Sentry.replayIntegration({
  maskAllText: true,
  blockAllMedia: true,
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
