import { GlobalState } from "@/reducers/global.slice";
import { UserState } from "@/reducers/user/user.slice";

export interface RootState {
  global: GlobalState;
  user: UserState;
}

export const getGlobal = (state: RootState) => state.global;
export const getUser = (state: RootState) => state.user;
