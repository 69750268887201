export const IS_PRODUCTION = window.Config.NODE_ENV === "production";

/**
 * @deprecated
 */
export const TOKEN = "account-auth";

export const OAUTH_PROVIDER_LOCAL_STORAGE_KEY = "__yulon_sso___oauth_provider_";

/**
 * @deprecated
 */
export const CLIENT_ID_LOCAL_STORAGE_KEY = "__yulon_sso___client_id_";

/**
 * @deprecated
 */
export const OAUTH_REDIRECT_URI_LOCAL_STORAGE_KEY =
  "__yulon_sso___oauth_redirect_uri_";

/**
 * @deprecated
 */
export const OAUTH_LOGIN_LOCAL_STORAGE_KEY = "__yulon_sso___oauth_login_";

export const OAUTH_CLIENT_INFO_LOCAL_STORAGE_KEY =
  "__yulon_sso___oauth_client_info_";

export const MAIN_TERMS_VERSION = 2;
