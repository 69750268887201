import {
  GetOTPCodeRequest,
  UserAgreementsResponse,
  LoginRequest,
  ResetPasswordRequest,
  SignupRequest,
  ThirdPartyLoginPageMetadataRequest,
  ThirdPartyLoginPageMetadataResponse,
  UpdateAccountRequest,
  UpdateUserProfileRequest,
  ValidateThirdPartyAccountRequest,
  PostUserAgreementsRequest,
  CheckOAuthResponse,
  CheckOAuthRequest,
  AddNewIdentifyAccountRequest,
  ThirdPartyLogoutRequest,
  ChangePasswordRequest,
  type PostUserEmailRequest,
  UserAgreementRequest,
} from "@/services/userApi";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export const USER_KEY = "user";

export type Account = {
  id: string;
  type: string;
  account: string;
  isPrimary: boolean;
};

export type Contact = {
  id: string;
  contact: string;
  type: string;
  isVerified: boolean;
};

export type User = {
  id: string;
  name: string;
  locale: string;
  gender: string;
  birthday: string;
  accounts: Account[];
  contacts: Contact[];
  sourceVendorId: string;
};

export interface UserState {
  user: {
    loading: boolean;
    loaded: boolean;
    data: {
      data: Partial<User>;
      current: "default";
    };
  };
  signin: {
    loading: boolean;
    loaded: boolean;
    data: {
      redirectUrl: string;
      showReCaptcha: boolean;
    };
  };
  signout: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  signup: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  resetPassword: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  otpCode: {
    loading: boolean;
    loaded: boolean;
    needCountDown: boolean;
    data: NonNullable<unknown>;
  };
  socialLogin: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  updateUserProfile: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  updateUserAccount: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
    error: boolean;
  };
  addNewIdentifyAccount: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  thirdPartyValidate: {
    loading: boolean;
    loaded: boolean;
    data: {
      isValid: boolean;
    };
  };
  thirdPartyLoginPageMetadata: {
    loading: boolean;
    loaded: boolean;
    data: ThirdPartyLoginPageMetadataResponse;
  };
  userAgreements: {
    loading: boolean;
    loaded: boolean;
    data: UserAgreementsResponse[];
  };
  userAgreementsUpdated: {
    loading: boolean;
    loaded: boolean;
    data: UserAgreementsResponse[];
  };
  checkOAuth: {
    loading: boolean;
    loaded: boolean;
    data: CheckOAuthResponse;
  };
  thirdPartySignout: {
    loading: boolean;
    loaded: boolean;
  };
  changePassword: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  changeEmail: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  postEmail: {
    loading: boolean;
    loaded: boolean;
    data: NonNullable<unknown>;
  };
  clientAgreements: {
    loading: boolean;
    loaded: boolean;
    data: UserAgreementsResponse[];
  };
}

export const initialUserState: UserState = {
  user: {
    loading: false,
    loaded: false,
    data: {
      data: {},
      current: "default",
    },
  },
  signin: {
    loading: false,
    loaded: false,
    data: {
      redirectUrl: "",
      showReCaptcha: false,
    },
  },
  signout: {
    loading: false,
    loaded: false,
    data: {},
  },
  signup: {
    loading: false,
    loaded: false,
    data: {},
  },
  resetPassword: {
    loading: false,
    loaded: false,
    data: {},
  },
  otpCode: {
    loading: false,
    loaded: false,
    needCountDown: false,
    data: {},
  },
  socialLogin: {
    loading: false,
    loaded: false,
    data: {},
  },
  updateUserProfile: {
    loading: false,
    loaded: false,
    data: {},
  },
  updateUserAccount: {
    loading: false,
    loaded: false,
    data: {},
    error: false,
  },
  addNewIdentifyAccount: {
    loading: false,
    loaded: false,
    data: {},
  },
  thirdPartyValidate: {
    loading: false,
    loaded: false,
    data: {
      isValid: false,
    },
  },
  thirdPartyLoginPageMetadata: {
    loading: false,
    loaded: false,
    data: {
      vendorId: "",
      vendorName: "",
      bannerUrl: "",
      logoUrl: "",
      socialLoginFlags: {
        google: false,
        facebook: false,
        apple: false,
        line: false,
      },
    },
  },
  userAgreements: {
    loading: false,
    loaded: false,
    data: [],
  },
  userAgreementsUpdated: {
    loading: false,
    loaded: false,
    data: [],
  },
  checkOAuth: {
    loading: false,
    loaded: false,
    data: {
      redirectUrl: "",
    },
  },
  thirdPartySignout: {
    loading: false,
    loaded: false,
  },
  changePassword: {
    loading: false,
    loaded: false,
    data: {},
  },
  changeEmail: {
    loading: false,
    loaded: false,
    data: {},
  },
  postEmail: {
    loading: false,
    loaded: false,
    data: {},
  },
  clientAgreements: {
    loading: false,
    loaded: false,
    data: [],
  },
};

const SignInRequest = (state: UserState) => {
  state.signin = {
    loading: true,
    loaded: false,
    data: { ...state.signin.data },
  };
  state.socialLogin = {
    loading: true,
    loaded: false,
    data: { ...state.socialLogin.data },
  };
};

const SignInSuccess = (state: UserState, action: any) => {
  state.signin = {
    loading: false,
    loaded: true,
    data: { ...action.payload.data },
  };
  state.socialLogin = {
    loading: false,
    loaded: true,
    data: { ...action.payload.data },
  };
};

const SignInFail = (state: UserState) => {
  state.signin = {
    loading: false,
    loaded: false,
    data: {
      redirectUrl: "",
      showReCaptcha: false,
    },
  };
  state.socialLogin = {
    loading: false,
    loaded: false,
    data: {},
  };
};

export const userSlice = createSlice({
  name: USER_KEY,
  initialState: initialUserState,
  reducers: {
    // signin
    postUserSignInRequest(state, _action: PayloadAction<LoginRequest>) {
      SignInRequest(state);
    },
    postUserSignInSuccess(state, action) {
      SignInSuccess(state, action);
    },
    postUserSignInFail(state) {
      SignInFail(state);
    },
    // if sign in fail so many time , we need to show reCaptcha
    showReCaptcha(state) {
      state.signin = {
        loading: false,
        loaded: false,
        data: {
          redirectUrl: "",
          showReCaptcha: true,
        },
      };
    },
    // check me info
    getUserInfoRequest(state) {
      SignInRequest(state);
    },
    getUserInfoSuccess(state, action) {
      state.user = {
        loading: false,
        loaded: true,
        data: {
          current: "default",
          ...action.payload,
        },
      };
    },
    getUserInfoFail(state) {
      SignInFail(state);
    },
    // signup
    postUserSignupRequest(state, _action: PayloadAction<SignupRequest>) {
      state.signup = {
        ...state.signup,
        loading: true,
        loaded: false,
      };
    },
    postUserSignupSuccess(state, action) {
      state.signup = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    postUserSignupFail(state) {
      state.signup = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // get OTP code
    getUserOTPCodeRequest(state, _action: PayloadAction<GetOTPCodeRequest>) {
      state.otpCode = {
        ...state.otpCode,
        loading: true,
        loaded: false,
      };
    },
    getUserOTPCodeSuccess(state, action) {
      state.otpCode = {
        loading: false,
        loaded: true,
        needCountDown: true,
        data: { ...action.payload },
      };
    },
    getUserOTPCodeFail(state) {
      state.otpCode = {
        loading: false,
        loaded: false,
        needCountDown: false,
        data: {},
      };
    },
    // get OTP code need countDown
    setOTPCodeCountDown(state) {
      state.otpCode.needCountDown = true;
    },
    // logout
    deleteUserLogoutRequest(
      state,
      action: {
        payload: {
          shouldRedirect?: boolean;
        };
      }
    ) {
      state.signout = {
        loading: true,
        loaded: false,
        data: {},
      };
    },
    deleteUserLogoutSuccess(state) {
      state.signout = {
        loading: false,
        loaded: true,
        data: {},
      };
    },
    deleteUserLogoutFail(state) {
      state.signout = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // reset password
    postUserResetPasswordRequest(
      state,
      _action: PayloadAction<ResetPasswordRequest>
    ) {
      state.resetPassword = {
        ...state.resetPassword,
        loading: true,
        loaded: false,
      };
    },
    postUserResetPasswordSuccess(state, action) {
      state.resetPassword = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    postUserResetPasswordFail(state) {
      state.resetPassword = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // update user profile
    patchUserProfileRequest(
      state,
      _action: PayloadAction<UpdateUserProfileRequest>
    ) {
      state.updateUserProfile = {
        ...state.updateUserProfile,
        loading: true,
        loaded: false,
      };
    },
    patchUserProfileSuccess(state, action) {
      state.updateUserProfile = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    patchUserProfileFail(state) {
      state.updateUserProfile = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // add new identify account
    postAddNewIdentifyAccountRequest(
      state,
      _action: PayloadAction<AddNewIdentifyAccountRequest>
    ) {
      state.addNewIdentifyAccount = {
        ...state.addNewIdentifyAccount,
        loading: true,
        loaded: false,
      };
    },
    postAddNewIdentifyAccountSuccess(state, action) {
      state.addNewIdentifyAccount = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    postAddNewIdentifyAccountFail(state) {
      state.addNewIdentifyAccount = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // update user account
    patchUserAccountRequest(
      state,
      _action: PayloadAction<UpdateAccountRequest>
    ) {
      state.updateUserAccount = {
        ...state.updateUserAccount,
        loading: true,
        loaded: false,
        error: false,
      };
    },
    patchUserAccountSuccess(state, action) {
      state.updateUserAccount = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
        error: false,
      };
    },
    patchUserAccountFail(state) {
      state.updateUserAccount = {
        loading: false,
        loaded: false,
        data: {},
        error: true,
      };
    },
    // third party validate
    postThirdPartyValidationRequest(
      state,
      _action: PayloadAction<ValidateThirdPartyAccountRequest>
    ) {
      state.thirdPartyValidate = {
        ...state.thirdPartyValidate,
        loading: true,
        loaded: false,
      };
    },
    postThirdPartyValidationSuccess(
      state,
      action: PayloadAction<{
        isValid: boolean;
      }>
    ) {
      state.thirdPartyValidate = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    postThirdPartyValidationFail(state) {
      state.thirdPartyValidate = {
        loading: false,
        loaded: false,
        data: {
          isValid: false,
        },
      };
    },
    // third party login page - show logo and social login button or not
    getThirdPartyLoginPageMetaRequest(
      state,
      _action: PayloadAction<ThirdPartyLoginPageMetadataRequest>
    ) {
      state.thirdPartyLoginPageMetadata = {
        ...state.thirdPartyLoginPageMetadata,
        loading: true,
        loaded: false,
      };
    },
    getThirdPartyLoginPageMetaSuccess(
      state,
      action: PayloadAction<ThirdPartyLoginPageMetadataResponse>
    ) {
      state.thirdPartyLoginPageMetadata = {
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    },
    getThirdPartyLoginPageMetaFail(state) {
      state.thirdPartyLoginPageMetadata = {
        loading: false,
        loaded: false,
        data: {
          vendorId: "",
          vendorName: "",
          logoUrl: "",
          bannerUrl: "",
          socialLoginFlags: {
            google: false,
            facebook: false,
            apple: false,
            line: false,
          },
        },
      };
    },
    // get user agreements
    getUserAgreementsRequest(
      state,
      action: PayloadAction<UserAgreementRequest>
    ) {
      state.userAgreements = {
        ...state.userAgreements,
        loading: true,
        loaded: false,
      };
    },
    getUserAgreementsSuccess(
      state,
      action: PayloadAction<UserAgreementsResponse[]>
    ) {
      state.userAgreements = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    getUserAgreementsFail(state) {
      state.userAgreements = {
        loading: false,
        loaded: false,
        data: [],
      };
    },
    // update user agreements
    postUserAgreementsRequest(
      state,
      _action: PayloadAction<PostUserAgreementsRequest>
    ) {
      state.userAgreementsUpdated = {
        ...state.userAgreementsUpdated,
        loading: true,
        loaded: false,
      };
    },
    postUserAgreementsSuccess(
      state,
      action: PayloadAction<UserAgreementsResponse[]>
    ) {
      state.userAgreementsUpdated = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    postUserAgreementsFail(state) {
      state.userAgreementsUpdated = {
        loading: false,
        loaded: false,
        data: [],
      };
    },
    // check oauth
    postCheckOAuthRequest(state, _action: PayloadAction<CheckOAuthRequest>) {
      state.checkOAuth = {
        ...state.checkOAuth,
        loading: true,
        loaded: false,
      };
    },
    postCheckOAuthSuccess(state, action: PayloadAction<CheckOAuthResponse>) {
      state.checkOAuth = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    postCheckOAuthFail(state) {
      state.checkOAuth = {
        loading: false,
        loaded: false,
        data: {
          redirectUrl: "",
        },
      };
    },
    // third party logout
    deleteThirdPartyLogoutRequest(
      state,
      _action: PayloadAction<ValidateThirdPartyAccountRequest>
    ) {
      state.thirdPartySignout = {
        ...state.thirdPartySignout,
        loading: true,
        loaded: false,
      };
    },
    deleteThirdPartyLogoutSuccess(state) {
      state.thirdPartySignout = {
        loading: false,
        loaded: true,
      };
    },
    deleteThirdPartyLogoutFail(state) {
      state.thirdPartySignout = {
        loading: false,
        loaded: false,
      };
    },
    // change password
    postChangePasswordRequest(
      state,
      _action: PayloadAction<ChangePasswordRequest>
    ) {
      state.changePassword = {
        ...state.changePassword,
        loading: true,
        loaded: false,
      };
    },
    postChangePasswordSuccess(state, action) {
      state.changePassword = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    postChangePasswordFail(state) {
      state.changePassword = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    // change email
    patchContactEmailRequest(state, _action: PayloadAction<any>) {
      state.changeEmail = {
        ...state.changeEmail,
        loading: true,
        loaded: false,
      };
    },
    patchContactEmailSuccess(state, action: PayloadAction<any>) {
      state.changeEmail = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    patchContactEmailFail(state) {
      state.changeEmail = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    postContactEmailRequest(
      state,
      _action: PayloadAction<PostUserEmailRequest>
    ) {
      state.changeEmail = {
        ...state.postEmail,
        loading: true,
        loaded: false,
      };
    },
    postContactEmailSuccess(
      state,
      action: PayloadAction<PostUserEmailRequest>
    ) {
      state.postEmail = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    postContactEmailFail(state) {
      state.postEmail = {
        loading: false,
        loaded: false,
        data: {},
      };
    },
    getClientAgreementsRequest(
      state,
      action: PayloadAction<UserAgreementRequest>
    ) {
      state.clientAgreements = {
        ...state.clientAgreements,
        loading: true,
        loaded: false,
      };
    },
    getClientAgreementsSuccess(
      state,
      action: PayloadAction<UserAgreementsResponse[]>
    ) {
      state.clientAgreements = {
        loading: false,
        loaded: true,
        data: action.payload,
      };
    },
    getClientAgreementsFail(state) {
      state.clientAgreements = {
        loading: false,
        loaded: false,
        data: [],
      };
    },
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
