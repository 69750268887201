import { Box, Skeleton, LinearProgress } from "@mui/material";

const PageLoading = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <Box className="w-full">
        <Box px={3} py={3} bgcolor="#fff" borderRadius={1}>
          <Skeleton height={30} />
        </Box>
      </Box>
      <Box px={3} py={4} borderRadius={3} className="w-full">
        <Box p={3} bgcolor="#fff" borderRadius={1}>
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} width="60%" />
        </Box>
      </Box>
    </div>
  );
};

export default PageLoading;
