import React from "react";
import DrawerHeader from "./DrawerHeader";
import DrawerLogout from "./DrawerLogout";
import AppPlugins from "./AppPlugins";
import ThirdPartyDrawerHeader from "./ThirdPartyDrawerHeader";
import SignLogo from "./SignLogo";
import HelmetHead from "./HelmetHead";
import UserBanner from "./UserBanner";

export type PluginType = {
  App: React.FC;
  [layoutName: string]: NonNullable<unknown>;
};

const plugins: PluginType = {
  App: AppPlugins,
  Drawer: {
    DrawerHeader,
    DrawerLogout,
  },
  ThirdPartyDrawer: {
    ThirdPartyDrawerHeader,
    HelmetHead,
  },
  User: {
    SignLogo,
    HelmetHead,
    UserBanner,
  },
  UserDrawer: {
    DrawerHeader,
    DrawerLogout,
    HelmetHead,
  },
  LogoutDrawer: {
    ThirdPartyDrawerHeader,
  },
  Basic: {
    DrawerHeader,
  },
};

export default plugins;
